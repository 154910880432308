.App {
  text-align: center;
}

svg {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.App-logo {
  z-index: 1;
  height: 40vmin;
  pointer-events: none;
  filter: drop-shadow(3px 5px 2px rgb(255 255 255/ 0.4));
}

.text {
  z-index: 1;
  font-weight: bold;
  font-size: 35px;
  text-shadow: 3px 5px 2px rgb(255 255 255 / 0.4);
}
.App-img {
  background-image: url("../public/sport5.jpg");
  min-height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  -moz-filter: blur(4px);
  -webkit-filter: blur(4px);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #61dafb;
}
